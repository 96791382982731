import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tab, Tabs, Box, Collapse, ListItemButton, ListItemText } from '@mui/material'
import _ from '@lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import PersonalDetails from './PersonalDetails'
import EducationDetails from './EducationDetails'
import RegistrationDetails from './RegistrationDetails'
import EstablishmentDetails from './EstablishmentDetails'
import TimingDetails from './TimingAndPayments'
import ServiceDetails from './ServiceDetails'
import AwardsDetails from './AwardsDetails'
import ProgressBar from './ProgressDetail'
import ConsultationTiming from './ConsultationTimingAndFee'
import ProfileSetupViewandEdit from '../ProfileSetupViewandEdit'
import { useUrlSearchParams } from '../../../hooks/use-url-search-params'
import profileStatusApi from '../../../services/ProfileSetup/profileStatus/api'
import ViewProfile from '../ProfileSetup/PersonalDetails/PersonalDetailsView'
import TimingandPaymentViewandEdit from '../TimingandPaymentViewEdit'
import ViewAwards from '../ProfileSetup/AwardsDetails/ViewAwards'
import ViewEducation from '../ProfileSetup/EducationDetails/ViewEducation'
import EstablishmentView from './EstablishmentDetails/EstablishmentDetailsView'
import ViewService from '../ProfileSetup/ServiceDetails/ViewService'
import MicroSetup from './MicroSetup/index'
import ConsultationTimingNoncare from './ConsultationTimingNoncare'
import SearchEngineOptimization from './SearchEngineOptimization'
import AiSiteMapIntegration from './AiBot'
import MenuSelection from './MenuSelection'
import themesConfig from 'app/fuse-configs/themesConfig'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Feedback from '../apps/FeedbackNew'
import AiChatHistory from './AiChatHistory'
import CustomLabels from './CustomLabels'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginBlock: 20,
		marginInline: 30,
		// padding: 20,
		border: '1px solid #FFFFFF',
		borderRadius: 12,
		background: '#ffffff',

		[theme.breakpoints.down('sm')]: {
			marginInline: 12,
			padding: 4,
		},
	},
	box: {
		borderRight: '1px solid #ccc',
		'& .MuiTabs-root': {
			color: '#2A2A2A',
			fontSize: 15,
			// maxHeight: '78vh',
			overflowY: 'auto',
			minWidth: 216,
			[theme.breakpoints.down('xs')]: {
				fontSize: 13,
			},
			[theme.breakpoints.down('md')]: {
				fontSize: 13,
			},
		},
		// '& .Mui-selected': {
		// 	color: '#0062DD',
		// },
		'& .Mui-selected': {
			'& .MuiTypography-root': {
				color: '#000',
				fontWeight: 600,
			},
			background: themesConfig?.lyfngo?.palette?.tertiary?.light,
			'& .MuiButtonBase-root': {
				alignItems: 'flex-start',
				justifyContent: 'center',
			},
		},
		'& .MuiTabs-indicator': {
			// backgroundColor: '#0062DD',
			// left: 6,
			display: 'none',
		},
		'& .MuiTabs-flexContainer': {
			gap: 8,
			[theme.breakpoints.down('xs')]: {
				gap: 2,
			},
			[theme.breakpoints.down('md')]: {
				gap: 2,
			},
		},
		'& .MuiButtonBase-root': {
			alignItems: 'flex-start',
			justifyContent: 'center',
			textAlign: 'left',
			'&:hover': {
				background: themesConfig?.lyfngo?.palette?.tertiary?.light,
			},
		},
		'& .MuiListItemButton-root': {
			padding: '8px 0',
		},
	},
	tabPanel: {
		width: '100%',
		'& .MuiTabs-indicator': {
			backgroundColor: '#0062DD',
			left: 6,
		},
		// '& .MuiButtonBase-root': {
		// 	paddingInline: '30px',
		// },
	},
}))

const tabStyles = makeStyles((theme) => ({
	box: {
		// padding: theme.spacing(1),
		minHeight: '78vh',
		maxHeight: '78vh',
		overflowY: 'auto',
		// padding: 20,
		paddingBottom: 0,
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 1),
		},
	},
}))

function TabPanel(props) {
	const { children, value, index, ...other } = props
	const classes = tabStyles()
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ width: '100%' }}
		>
			{value === index && (
				<Box className={classes.box}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default function BasicTabs({ history, match }) {
	const [value, setValue] = useState(0)
	const [submenuIndex, setSubmenuIndex] = useState(0)
	const classes = useStyles()
	const groupUserName = useSelector((state) => state?.GroupUser?.groupUserName)
	const action = _.get(match, 'params.action', '')
	const isViewMode = _.isEqual(action, 'view')
	const viewUuid = _.get(match, 'params.uuid', '')
	const uuid = useSelector((state) => state?.Authentication?.uuid)
	const { groupName } = useSelector((state) => state?.dynamicMenu?.userData || {})
	const isCare = _.isEqual(groupName, 'health care')

	const StickyTabs = useMemo(() => {
		if (_.isEqual(groupUserName, 'owner') || _.isEmpty(groupUserName)) {
			return [
				{
					id: 'subMenu',
					label: 'Basic Setup',
					subMenus: [
						{
							id: 'microsetup',
							label: 'Microsite Setup',
							tabPanel: MicroSetup,
						},
						{
							id: 'registrationDocument',
							label: 'Registration and Document ',
							tabPanel: isViewMode ? ProfileSetupViewandEdit : RegistrationDetails,
						},
						{
							id: 'establishment',
							label: 'Establishment Details',
							tabPanel: isViewMode ? EstablishmentView : EstablishmentDetails,
						},

						...(!isCare
							? [
									{
										id: 'consultationTimingAndFee',
										label: 'Consultation Timing & Fee',
										tabPanel: ConsultationTimingNoncare,
									},
							  ]
							: []),
						{
							id: 'awardsAndRec',
							label: 'Awards & Recognition',
							tabPanel: isViewMode ? ViewAwards : AwardsDetails,
						},
						{
							id: 'customLabels',
							label: 'Custom Labels',
							tabPanel: CustomLabels,
						},
					],
				},
				// {
				// 	id: 'microsetup',
				// 	label: 'Microsite Setup',
				// 	tabPanel: MicroSetup,
				// },
				// {
				// 	id: 'registrationDocument',
				// 	label: 'Registration and Document ',
				// 	tabPanel: isViewMode ? ProfileSetupViewandEdit : RegistrationDetails,
				// },
				// {
				// 	id: 'establishment',
				// 	label: 'Establishment Details',
				// 	tabPanel: isViewMode ? EstablishmentView : EstablishmentDetails,
				// },
				// {
				// 	id: 'awardsAndRec',
				// 	label: 'Awards & Recognition',
				// 	tabPanel: isViewMode ? ViewAwards : AwardsDetails,
				// },
				// the below TAB comes only for the NON-CARE group
				// ...(isCare
				// 	? []
				// 	: [
				// 			{
				// 				id: 'consultationTimingAndFee',
				// 				label: 'Consultation Timing & Fee',
				// 				tabPanel: ConsultationTimingNoncare,
				// 			},
				// 	  ]),
				// {
				// 	id: 'timingAndPayments',
				// 	label: 'Timing & Payments',
				// 	tabPanel: isViewMode || isEditMode ? TimingandPaymentViewandEdit : TimingDetails,
				// },

				{
					id: 'seoOptimiztion',
					label: 'Configure SEO',
					tabPanel: SearchEngineOptimization,
				},
				{
					id: 'aisitemapintegration',
					label: 'Web ChatBot',
					tabPanel: AiSiteMapIntegration,
				},
				{
					id: 'menuselection',
					label: 'Menu Settings',
					tabPanel: MenuSelection,
				},
				{
					id: 'feedback',
					label: 'Feedback',
					tabPanel: Feedback,
				},
				{
					id: 'chathistory',
					label: 'Chat History',
					tabPanel: AiChatHistory,
				},
			]
		} else if (groupUserName === 'doctor' || 'coach' || 'beautician') {
			return [
				{
					id: 'personalDetails',
					label: 'Personal Details',
					tabPanel: isViewMode ? ViewProfile : PersonalDetails,
				},
				{
					id: 'educationSpecialisation',
					label: 'Education & Specialisation ',
					tabPanel: isViewMode ? ViewEducation : EducationDetails,
				},
				{
					id: 'registrationDocument',
					label: 'Registration & Document ',
					tabPanel: isViewMode ? ProfileSetupViewandEdit : RegistrationDetails,
				},
				{
					id: 'consultationTimingAndFee',
					label: 'Consultation Timing & Fee',
					tabPanel: ConsultationTiming,
				},
				{
					id: 'serviceAndExperience',
					label: 'Service & Experience',
					tabPanel: isViewMode ? ViewService : ServiceDetails,
				},
				{
					id: 'awardsAndRec',
					label: 'Awards & Recognition',
					tabPanel: isViewMode ? ViewAwards : AwardsDetails,
				},

				{
					id: 'seoOptimiztion',
					label: 'Configure SEO',
					tabPanel: SearchEngineOptimization,
				},
			]
		} else if (_.isEqual(groupUserName, 'owner & individual')) {
			return [
				{
					id: 'personalDetails',
					label: 'Personal Details',
					tabPanel: PersonalDetails,
				},
				{
					id: 'educationSpecialisation',
					label: 'Education & Specialisation ',
					tabPanel: EducationDetails,
				},
				{
					id: 'registrationDocument',
					label: 'Registration & Document ',
					tabPanel: RegistrationDetails,
				},

				{
					id: 'establishmentDetails',
					label: 'Establishment Details',
					tabPanel: EstablishmentDetails,
				},
				{
					id: 'timingAndPayments',
					label: 'Timing & Payments',
					tabPanel: TimingDetails,
				},
				{
					id: 'consultationTimingAndFee',
					label: 'Consultation Timing & Fee',
					tabPanel: ConsultationTiming,
				},
				{
					id: 'serviceAndExperience',
					label: 'Service & Experience',
					tabPanel: ServiceDetails,
				},
				{
					id: 'awardsAndRec',
					label: 'awards & membership',
					tabPanel: AwardsDetails,
				},
				// {
				// 	id: 'seoOptimiztion',
				// 	label: 'Configure SEO',
				// 	tabPanel: SearchEngineOptimization,
				// },
			]
		} else if (_.isEqual(groupUserName, 'Freelencer')) {
			return [
				{
					id: 'personalDetails',
					label: 'Personal Details',
					tabPanel: PersonalDetails,
				},
				{
					id: 'educationSpecialisation',
					label: 'Education & Specialisation ',
					tabPanel: EducationDetails,
				},
				{
					id: 'registrationDocument',
					label: 'Registration & Document ',
					tabPanel: RegistrationDetails,
				},

				{
					id: 'establishment',
					label: 'Establishment Details',
					tabPanel: EstablishmentDetails,
				},

				{
					id: 'consultationTimingAndFee',
					label: 'consultationTimingAndFee',
					tabPanel: ConsultationTiming,
				},
				{
					id: 'awardsAndRec',
					label: 'awards & membership',
					tabPanel: AwardsDetails,
				},
			]
		}
	}, [groupUserName, isViewMode, isCare])

	const defaultTabId = StickyTabs[0].id

	const [pageQuery, setPageQuery] = useUrlSearchParams({ tabId: 'registrationDocument' }, { tabId: String })
	const activeTabIdx = useMemo(() => {
		const index = _.findIndex(StickyTabs, { id: _.get(pageQuery, 'tabId') || defaultTabId })
		return index === -1 ? 0 : index
	}, [StickyTabs, defaultTabId, pageQuery])

	const [profilePercentage, setProfilePercentage] = useState(0)
	const userType = useSelector((state) => state?.Authentication?.userType)

	// useEffect(() => {
	// 	const onSuccess = (res) => {
	// 		setProfilePercentage(res?.data?.data?.percentage)
	// 	}
	// 	const onFailure = (err) => {
	// 		setProfilePercentage(0)
	// 	}
	// 	let currentId = viewUuid ? viewUuid : uuid
	// 	userType && uuid && profileStatusApi.getProfilePercentage(userType, currentId).then(onSuccess, onFailure)
	// }, [userType, uuid, viewUuid, value])

	const handleChange = (event, newValue) => {
		if (_.isEqual(groupUserName, 'owner')) {
			if (!_.isEqual(newValue, 0)) {
				setValue(newValue)
				setPageQuery({ tabId: StickyTabs[newValue].id }, true)
			} else {
				setPageQuery({ tabId: 'microsetup' }, true)
				setValue(0)
				setSubmenuIndex(0)
			}
		} else {
			setValue(newValue)
			setPageQuery({ tabId: StickyTabs[newValue].id }, true)
		}
	}
	const handleSubMenuChange = (value, idx) => {
		setPageQuery({ tabId: value.id }, true)
		setValue(0)
		setSubmenuIndex(idx)
	}
	// const moveNextTab = () => {
	// 	let currentId = value + 1
	// 	setValue(currentId)
	// 	setPageQuery({ tabId: StickyTabs[currentId].id }, true)
	// }

	const moveNextTab = () => {
		if (_.isEqual(groupUserName, 'owner')) {
			if (
				_.isEqual(pageQuery.tabId, 'microsetup') ||
				_.isEqual(pageQuery.tabId, 'registrationDocument') ||
				_.isEqual(pageQuery.tabId, 'establishment') ||
				_.isEqual(pageQuery.tabId, 'consultationTimingAndFee') ||
				_.isEqual(pageQuery.tabId, 'awardsAndRec')
			) {
				let currentId = submenuIndex + 1
				setValue(0)
				setSubmenuIndex(currentId)
				setPageQuery({ tabId: StickyTabs[0].subMenus[currentId].id }, true)
			} else {
				let currentId = value + 1
				setValue(currentId)
				setPageQuery({ tabId: StickyTabs[currentId].id }, true)
			}
		} else {
			let currentId = value + 1
			setValue(currentId)
			setPageQuery({ tabId: StickyTabs[currentId].id }, true)
		}
	}

	const moveBackTab = () => {
		if (_.isEqual(groupUserName, 'owner')) {
			if (
				_.isEqual(pageQuery.tabId, 'seoOptimiztion') ||
				_.isEqual(pageQuery.tabId, 'registrationDocument') ||
				_.isEqual(pageQuery.tabId, 'establishment') ||
				_.isEqual(pageQuery.tabId, 'awardsAndRec') ||
				_.isEqual(pageQuery.tabId, 'consultationTimingAndFee') ||
				_.isEqual(pageQuery.tabId, 'customLabels')
			) {
				let currentId = _.isEqual(pageQuery.tabId, 'seoOptimiztion') ? 4 : submenuIndex - 1
				setValue(0)
				setSubmenuIndex(currentId)
				setPageQuery({ tabId: StickyTabs[0]?.subMenus[currentId]?.id }, true)
			} else {
				let currentId = value - 1
				setValue(currentId)
				setPageQuery({ tabId: StickyTabs[currentId]?.id }, true)
			}
		} else {
			let currentId = value - 1
			setValue(currentId)
			setPageQuery({ tabId: StickyTabs[currentId]?.id }, true)
		}
	}

	return (
		<>
			<ProgressBar percent={profilePercentage} isViewMode={isViewMode} history={history} />
			<div className={classes.root}>
				<Box sx={{ width: '100%', display: 'flex' }}>
					<Box className={classes.box}>
						<Tabs
							variant='scrollable'
							size='small'
							orientation='vertical'
							value={activeTabIdx}
							// indicatorColor='primary'
							color='primary'
							textColor='secondary'
							// scrollButtons
							// allowScrollButtonsMobile
							aria-label='basic tabs example'
							onChange={handleChange}
						>
							{_.map(StickyTabs, (tab, index) => (
								<Tab key={tab.id} index={index} label={<Typography variant='body2'>{tab.label}</Typography>} {...a11yProps(index)} />
							))}
						</Tabs>
					</Box>

					<div className={classes.tabPanel}>
						{_.map(StickyTabs, (tab, index) =>
							_.isEqual(tab.id, 'subMenu') ? (
								<TabPanel
									key={tab.id}
									value={value}
									index={index}
									sx={{
										'& .MuiTabs-indicator': {
											backgroundColor: '#0062DD',
											left: 6,
											// display: 'none',
										},
									}}
								>
									<Tabs
										variant='scrollable'
										size='small'
										orientation='horizontal'
										value={submenuIndex}
										indicatorColor='primary'
										color='primary'
										textColor='secondary'
										// scrollButtons
										// allowScrollButtonsMobile
										aria-label='basic tabs example'
										// onChange={handleChange}
										sx={{ borderBottom: '1px solid #ccc' }}
									>
										{_.map(
											StickyTabs,
											(tab, index) =>
												_.isEqual(tab.id, 'subMenu') &&
												_.map(tab?.subMenus, (listItem, idx) => (
													<Tab
														key={listItem.id}
														index={idx}
														label={
															<Typography
																variant='body2'
																onClick={() => {
																	handleSubMenuChange(listItem, idx)
																}}
																sx={{ paddingInline: '20px' }}
															>
																{listItem.label}
															</Typography>
														}
														{...a11yProps(index)}
													/>
												))
										)}
									</Tabs>
									<div style={{ padding: '20px' }}>
										{React.createElement(tab.subMenus[submenuIndex]?.tabPanel, {
											groupUserName,
											moveNextTab,
											moveBackTab,
											profilePercentage,
											history,
											match,
										})}
									</div>
								</TabPanel>
							) : (
								<TabPanel key={tab.id} value={activeTabIdx} index={index}>
									<div style={{ padding: '20px' }}>
										{React.createElement(tab.tabPanel, {
											groupUserName,
											moveNextTab,
											moveBackTab,
											profilePercentage,
											history,
											match,
										})}
									</div>
								</TabPanel>
							)
						)}
					</div>
				</Box>
			</div>
		</>
	)
}
