import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	postCustomLabels: (body, mastUuid, domainName) => {
		return axios.post(`${API_ENDPOINTS.POST_CUSTOM_LABELS}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastUuid } },
			params: { domainName },
		})
	},
	getCustomLabels: (mastUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_CUSTOM_LABELS}`, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastUuid } },
		})
	},
}
